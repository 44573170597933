import React from 'react';
import useTheme from '../../utils/useTheme';

export interface UnknownErrorProps {
  className?: string;
}

const UnknownError = ({ className }: UnknownErrorProps): JSX.Element => {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      width={390}
      height={225}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M138.872 128.807c25.076 0 45.404-20.328 45.404-45.404 0-25.075-20.328-45.403-45.404-45.403-25.075 0-45.403 20.328-45.403 45.403 0 25.076 20.328 45.404 45.403 45.404Z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M76.5 48.901c0 16.52 10.364 29.887 23.17 29.887L76.5 48.901Z"
        fill="#252627"
      />
      <path
        d="M99.672 78.788c0-16.705 11.565-30.223 25.857-30.223L99.672 78.788ZM84.894 50.399c0 15.692 6.61 28.39 14.776 28.39l-14.776-28.39Z"
        fill="#5384EE"
      />
      <path
        d="M99.672 78.788c0-21.345 13.367-38.618 29.887-38.618L99.672 78.788Z"
        fill="#252627"
      />
      <path
        d="M94.797 78.999s3.286-.102 4.276-.807c.991-.705 5.055-1.547 5.301-.416.245 1.131 4.938 5.625 1.228 5.655-3.71.03-8.62-.578-9.609-1.18-.988-.602-1.196-3.252-1.196-3.252Z"
        fill="#A8A8A8"
      />
      <path
        opacity={0.2}
        d="M105.668 83.037c-3.71.03-8.62-.577-9.608-1.18-.753-.458-1.053-2.103-1.153-2.862l-.11.004s.208 2.65 1.196 3.252c.989.602 5.899 1.21 9.609 1.18 1.071-.008 1.441-.39 1.42-.954-.148.341-.557.554-1.354.56Z"
        fill="#072252"
      />
      <path
        d="M118.838 133.91c22.015 0 39.861-2.428 39.861-5.423s-17.846-5.423-39.861-5.423c-22.015 0-39.861 2.428-39.861 5.423s17.846 5.423 39.861 5.423Z"
        fill="#606060"
      />
      <path
        opacity={0.1}
        d="M118.837 133.04c18.483 0 33.467-2.039 33.467-4.553 0-2.515-14.984-4.553-33.467-4.553-18.482 0-33.466 2.038-33.466 4.553 0 2.514 14.984 4.553 33.466 4.553Z"
        fill="#072252"
      />
      <path
        d="M254.833 187c33.316 0 60.324-3.674 60.324-8.207 0-4.532-27.008-8.207-60.324-8.207-33.317 0-60.325 3.675-60.325 8.207 0 4.533 27.008 8.207 60.325 8.207ZM142.71 180.526c20.014 0 36.238-2.207 36.238-4.93s-16.224-4.93-36.238-4.93c-20.013 0-36.237 2.207-36.237 4.93s16.224 4.93 36.237 4.93Z"
        fill="#606060"
      />
      <path
        d="M175.109 171.439c19.185 4.903 56.062-6.394 60.112-19.184l-60.112 19.184Z"
        fill="#252627"
      />
      <path
        d="M175.109 171.439c19.185 4.903 56.062-6.394 60.112-19.184M142.496 93.209s27.711-7.674 29.416 17.053c1.706 24.727-22.808 31.761-3.623 36.664M115.723 152.528s8.328-2.306 8.841 5.126c.512 7.431-6.855 9.546-1.089 11.019"
        stroke="#2F2E41"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="m242.49 158.061-1.679 10.721s-8.267 4.392-2.455 4.521c5.813.129 33.197 0 33.197 0s5.296 0-3.1-4.65l-1.679-11.237-24.284.645Z"
        fill="#252627"
      />
      <path
        opacity={0.1}
        d="M237.185 173.187c1.32-1.175 3.624-2.399 3.624-2.399l1.679-10.721 24.284.023 1.679 10.569c1.958 1.085 3.171 1.916 3.88 2.554 1.079-.246 2.257-1.161-3.88-4.56l-1.679-11.237-24.284.645-1.679 10.721s-6.947 3.691-3.624 4.405Z"
        fill="#072252"
      />
      <path
        d="M305.36 83.337H203.647a3.846 3.846 0 0 0-3.846 3.847V155.7a3.846 3.846 0 0 0 3.846 3.846H305.36a3.846 3.846 0 0 0 3.847-3.846V87.184a3.847 3.847 0 0 0-3.847-3.847Z"
        fill="#252627"
      />
      <path d="M305.524 87.406H203.48v57.092h102.044V87.406Z" fill="#606060" />
      <path
        d="M254.501 86.244a.646.646 0 1 0 0-1.292.646.646 0 0 0 0 1.292Z"
        fill="#F2F2F2"
      />
      <path
        d="M309.207 149.02v6.68a3.85 3.85 0 0 1-3.847 3.847H203.647a3.854 3.854 0 0 1-2.72-1.127 3.856 3.856 0 0 1-1.126-2.72v-6.68h109.406ZM283.048 180.278v1.292h-69.493v-1.034l.095-.258 1.713-4.65h66.264l1.421 4.65ZM309.791 179.014c-.127.541-.605 1.112-1.684 1.652-3.876 1.937-11.755-.517-11.755-.517s-6.071-1.033-6.071-3.746c.171-.117.348-.222.532-.316 1.63-.862 7.031-2.989 16.609.09a3.988 3.988 0 0 1 1.821 1.193c.388.454.693 1.03.548 1.644Z"
        fill="#252627"
      />
      <path
        opacity={0.1}
        d="M309.794 179.013c-4.743 1.817-8.971 1.953-13.31-1.06-2.188-1.519-4.176-1.895-5.668-1.867 1.63-.862 7.031-2.989 16.609.09a4.004 4.004 0 0 1 1.821 1.193c.388.455.693 1.031.548 1.644Z"
        fill="#072252"
      />
      <path
        d="M303.843 177.953c.928 0 1.679-.231 1.679-.516 0-.286-.751-.517-1.679-.517-.927 0-1.679.231-1.679.517 0 .285.752.516 1.679.516ZM254.501 156.77a2.454 2.454 0 1 0 0-4.908 2.454 2.454 0 0 0 0 4.908Z"
        fill="#F2F2F2"
      />
      <path
        opacity={0.1}
        d="M283.048 180.278v1.292h-69.493v-1.033l.095-.259h69.398Z"
        fill="#072252"
      />
      <path d="M143.99 71.893H99.652v51.585h44.338V71.893Z" fill="#252627" />
      <path
        d="M148.466 66.777h-53.29v18.332h53.29V66.777ZM148.466 88.52h-53.29v18.331h53.29V88.519ZM148.466 110.262h-53.29v18.332h53.29v-18.332Z"
        fill="#606060"
      />
      <path
        opacity={0.4}
        d="M137.809 69.974h-3.411v3.41h3.411v-3.41Z"
        fill="#5384EE"
      />
      <path
        opacity={0.8}
        d="M142.711 69.974h-3.41v3.41h3.41v-3.41Z"
        fill="#5384EE"
      />
      <path d="M147.614 69.974h-3.411v3.41h3.411v-3.41Z" fill="#5384EE" />
      <path
        opacity={0.4}
        d="M137.809 91.503h-3.411v3.411h3.411v-3.41Z"
        fill="#5384EE"
      />
      <path
        opacity={0.8}
        d="M142.711 91.503h-3.41v3.411h3.41v-3.41Z"
        fill="#5384EE"
      />
      <path d="M147.614 91.503h-3.411v3.411h3.411v-3.41Z" fill="#5384EE" />
      <path
        opacity={0.4}
        d="M137.809 113.033h-3.411v3.41h3.411v-3.41Z"
        fill="#5384EE"
      />
      <path
        opacity={0.8}
        d="M142.711 113.033h-3.41v3.41h3.41v-3.41Z"
        fill="#5384EE"
      />
      <path d="M147.614 113.033h-3.411v3.41h3.411v-3.41Z" fill="#5384EE" />
      <path
        d="M143.988 169.521c9.301 0 16.84-7.54 16.84-16.84s-7.539-16.84-16.84-16.84c-9.3 0-16.84 7.54-16.84 16.84s7.54 16.84 16.84 16.84Z"
        fill="#83B4FF"
      />
      <path
        d="M141.432 165.897h-5.116v9.166h5.116v-9.166ZM151.663 165.897h-5.116v9.166h5.116v-9.166Z"
        fill="#83B4FF"
      />
      <path
        d="M140.58 176.768c2.354 0 4.263-.716 4.263-1.598 0-.883-1.909-1.599-4.263-1.599-2.355 0-4.264.716-4.264 1.599 0 .882 1.909 1.598 4.264 1.598ZM150.81 176.555c2.355 0 4.263-.716 4.263-1.599s-1.908-1.599-4.263-1.599c-2.354 0-4.263.716-4.263 1.599s1.909 1.599 4.263 1.599Z"
        fill="#83B4FF"
      />
      <path
        d="M144.416 154.173a5.755 5.755 0 1 0 0-11.51 5.755 5.755 0 1 0 0 11.51Z"
        fill="#fff"
      />
      <path
        d="M144.415 150.336a1.918 1.918 0 1 0 0-3.837 1.918 1.918 0 0 0 0 3.837ZM127.655 137.021c-1.36-6.09 2.987-12.243 9.708-13.744 6.722-1.501 13.273 2.219 14.633 8.308 1.359 6.09-3.095 8.336-9.817 9.837-6.721 1.501-13.164 1.688-14.524-4.401Z"
        fill="#5384EE"
      />
      <path
        d="M159.782 149.322c4.275-1.83 7.275-4.401 6.7-5.743-.574-1.342-4.505-.947-8.78.883-4.275 1.83-7.275 4.401-6.701 5.743.574 1.342 4.506.947 8.781-.883ZM128.235 160.62c4.275-1.83 7.275-4.401 6.7-5.743-.574-1.342-4.505-.947-8.78.883-4.275 1.829-7.275 4.401-6.701 5.743.575 1.342 4.506.947 8.781-.883Z"
        fill="#83B4FF"
      />
      <path
        d="M153.795 157.584c0 1.648-4.244 4.903-8.953 4.903s-9.166-3.042-9.166-4.69c0-1.648 4.457-1.279 9.166-1.279 4.709 0 8.953-.582 8.953 1.066Z"
        fill="#fff"
      />
    </svg>
  ) : (
    <svg
      width={390}
      height={225}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M138.872 128.807c25.076 0 45.404-20.328 45.404-45.404 0-25.075-20.328-45.403-45.404-45.403-25.075 0-45.403 20.328-45.403 45.403 0 25.076 20.328 45.404 45.403 45.404Z"
        fill="#5384EE"
        fillOpacity={0.2}
      />
      <path
        d="M76.5 48.901c0 16.52 10.364 29.887 23.17 29.887L76.5 48.901Z"
        fill="#9EC0EA"
      />
      <path
        d="M99.672 78.788c0-16.705 11.565-30.223 25.857-30.223L99.672 78.788ZM84.894 50.399c0 15.692 6.61 28.39 14.776 28.39l-14.776-28.39Z"
        fill="#5384EE"
      />
      <path
        d="M99.672 78.788c0-21.345 13.367-38.618 29.887-38.618L99.672 78.788Z"
        fill="#9EC0EA"
      />
      <path
        d="M94.797 78.999s3.286-.102 4.276-.807c.991-.705 5.055-1.547 5.301-.416.245 1.131 4.938 5.625 1.228 5.655-3.71.03-8.62-.578-9.609-1.18-.988-.602-1.196-3.252-1.196-3.252Z"
        fill="#A8A8A8"
      />
      <path
        opacity={0.2}
        d="M105.668 83.037c-3.71.03-8.62-.577-9.608-1.18-.753-.458-1.053-2.103-1.153-2.862l-.11.004s.208 2.65 1.196 3.252c.989.602 5.899 1.21 9.609 1.18 1.071-.008 1.441-.39 1.42-.954-.148.341-.557.554-1.354.56Z"
        fill="#072252"
      />
      <path
        d="M118.838 133.91c22.015 0 39.861-2.428 39.861-5.423s-17.846-5.423-39.861-5.423c-22.015 0-39.861 2.428-39.861 5.423s17.846 5.423 39.861 5.423Z"
        fill="#fff"
      />
      <path
        opacity={0.1}
        d="M118.837 133.04c18.483 0 33.467-2.039 33.467-4.553 0-2.515-14.984-4.553-33.467-4.553-18.482 0-33.466 2.038-33.466 4.553 0 2.514 14.984 4.553 33.466 4.553Z"
        fill="#072252"
      />
      <path
        d="M254.833 187c33.316 0 60.324-3.674 60.324-8.207 0-4.532-27.008-8.207-60.324-8.207-33.317 0-60.325 3.675-60.325 8.207 0 4.533 27.008 8.207 60.325 8.207Z"
        fill="url(#a)"
      />
      <path
        d="M142.71 180.526c20.014 0 36.238-2.207 36.238-4.93s-16.224-4.93-36.238-4.93c-20.013 0-36.237 2.207-36.237 4.93s16.224 4.93 36.237 4.93Z"
        fill="url(#b)"
      />
      <path
        d="M175.109 171.439c19.185 4.903 56.062-6.394 60.112-19.184l-60.112 19.184Z"
        fill="#9EC0EA"
      />
      <path
        d="M175.109 171.439c19.185 4.903 56.062-6.394 60.112-19.184M142.496 93.209s27.711-7.674 29.416 17.053c1.706 24.727-22.808 31.761-3.623 36.664M115.723 152.528s8.328-2.306 8.841 5.126c.512 7.431-6.855 9.546-1.089 11.019"
        stroke="#2F2E41"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="m242.49 158.061-1.679 10.721s-8.267 4.392-2.455 4.521c5.813.129 33.197 0 33.197 0s5.296 0-3.1-4.65l-1.679-11.237-24.284.645Z"
        fill="#9EC0EA"
      />
      <path
        opacity={0.1}
        d="M237.185 173.187c1.32-1.175 3.624-2.399 3.624-2.399l1.679-10.721 24.284.023 1.679 10.569c1.958 1.085 3.171 1.916 3.88 2.554 1.079-.246 2.257-1.161-3.88-4.56l-1.679-11.237-24.284.645-1.679 10.721s-6.947 3.691-3.624 4.405Z"
        fill="#072252"
      />
      <path
        d="M305.36 83.337H203.647a3.846 3.846 0 0 0-3.846 3.847V155.7a3.846 3.846 0 0 0 3.846 3.846H305.36a3.846 3.846 0 0 0 3.847-3.846V87.184a3.847 3.847 0 0 0-3.847-3.847Z"
        fill="#9EC0EA"
      />
      <path d="M305.524 87.406H203.48v57.092h102.044V87.406Z" fill="#fff" />
      <path
        d="M254.501 86.244a.646.646 0 1 0 0-1.292.646.646 0 0 0 0 1.292Z"
        fill="#F2F2F2"
      />
      <path
        d="M309.207 149.02v6.68a3.85 3.85 0 0 1-3.847 3.847H203.647a3.854 3.854 0 0 1-2.72-1.127 3.856 3.856 0 0 1-1.126-2.72v-6.68h109.406ZM283.048 180.278v1.292h-69.493v-1.034l.095-.258 1.713-4.65h66.264l1.421 4.65ZM309.791 179.014c-.127.541-.605 1.112-1.684 1.652-3.876 1.937-11.755-.517-11.755-.517s-6.071-1.033-6.071-3.746c.171-.117.348-.222.532-.316 1.63-.862 7.031-2.989 16.609.09a3.988 3.988 0 0 1 1.821 1.193c.388.454.693 1.03.548 1.644Z"
        fill="#9EC0EA"
      />
      <path
        opacity={0.1}
        d="M309.794 179.013c-4.743 1.817-8.971 1.953-13.31-1.06-2.188-1.519-4.176-1.895-5.668-1.867 1.63-.862 7.031-2.989 16.609.09a4.004 4.004 0 0 1 1.821 1.193c.388.455.693 1.031.548 1.644Z"
        fill="#072252"
      />
      <path
        d="M303.843 177.953c.928 0 1.679-.231 1.679-.516 0-.286-.751-.517-1.679-.517-.927 0-1.679.231-1.679.517 0 .285.752.516 1.679.516ZM254.501 156.77a2.454 2.454 0 1 0 0-4.908 2.454 2.454 0 0 0 0 4.908Z"
        fill="#F2F2F2"
      />
      <path
        opacity={0.1}
        d="M283.048 180.278v1.292h-69.493v-1.033l.095-.259h69.398Z"
        fill="#072252"
      />
      <path d="M143.99 71.893H99.652v51.585h44.338V71.893Z" fill="#9EC0EA" />
      <path
        d="M148.466 66.777h-53.29v18.332h53.29V66.777ZM148.466 88.52h-53.29v18.331h53.29V88.519ZM148.466 110.262h-53.29v18.332h53.29v-18.332Z"
        fill="#fff"
      />
      <path
        opacity={0.4}
        d="M137.809 69.974h-3.411v3.41h3.411v-3.41Z"
        fill="#5384EE"
      />
      <path
        opacity={0.8}
        d="M142.711 69.974h-3.41v3.41h3.41v-3.41Z"
        fill="#5384EE"
      />
      <path d="M147.614 69.974h-3.411v3.41h3.411v-3.41Z" fill="#5384EE" />
      <path
        opacity={0.4}
        d="M137.809 91.503h-3.411v3.411h3.411v-3.41Z"
        fill="#5384EE"
      />
      <path
        opacity={0.8}
        d="M142.711 91.503h-3.41v3.411h3.41v-3.41Z"
        fill="#5384EE"
      />
      <path d="M147.614 91.503h-3.411v3.411h3.411v-3.41Z" fill="#5384EE" />
      <path
        opacity={0.4}
        d="M137.809 113.033h-3.411v3.41h3.411v-3.41Z"
        fill="#5384EE"
      />
      <path
        opacity={0.8}
        d="M142.711 113.033h-3.41v3.41h3.41v-3.41Z"
        fill="#5384EE"
      />
      <path d="M147.614 113.033h-3.411v3.41h3.411v-3.41Z" fill="#5384EE" />
      <path
        d="M143.988 169.521c9.301 0 16.84-7.54 16.84-16.84s-7.539-16.84-16.84-16.84c-9.3 0-16.84 7.54-16.84 16.84s7.54 16.84 16.84 16.84Z"
        fill="#83B4FF"
      />
      <path
        d="M141.432 165.897h-5.116v9.166h5.116v-9.166ZM151.663 165.897h-5.116v9.166h5.116v-9.166Z"
        fill="#83B4FF"
      />
      <path
        d="M140.58 176.768c2.354 0 4.263-.716 4.263-1.598 0-.883-1.909-1.599-4.263-1.599-2.355 0-4.264.716-4.264 1.599 0 .882 1.909 1.598 4.264 1.598ZM150.81 176.555c2.355 0 4.263-.716 4.263-1.599s-1.908-1.599-4.263-1.599c-2.354 0-4.263.716-4.263 1.599s1.909 1.599 4.263 1.599Z"
        fill="#83B4FF"
      />
      <path
        d="M144.416 154.173a5.755 5.755 0 1 0 0-11.51 5.755 5.755 0 1 0 0 11.51Z"
        fill="#fff"
      />
      <path
        d="M144.415 150.336a1.918 1.918 0 1 0 0-3.837 1.918 1.918 0 0 0 0 3.837ZM127.655 137.021c-1.36-6.09 2.987-12.243 9.708-13.744 6.722-1.501 13.273 2.219 14.633 8.308 1.359 6.09-3.095 8.336-9.817 9.837-6.721 1.501-13.164 1.688-14.524-4.401Z"
        fill="#5384EE"
      />
      <path
        d="M159.782 149.322c4.275-1.83 7.275-4.401 6.7-5.743-.574-1.342-4.505-.947-8.78.883-4.275 1.83-7.275 4.401-6.701 5.743.574 1.342 4.506.947 8.781-.883ZM128.235 160.62c4.275-1.83 7.275-4.401 6.7-5.743-.574-1.342-4.505-.947-8.78.883-4.275 1.829-7.275 4.401-6.701 5.743.575 1.342 4.506.947 8.781-.883Z"
        fill="#83B4FF"
      />
      <path
        d="M153.795 157.584c0 1.648-4.244 4.903-8.953 4.903s-9.166-3.042-9.166-4.69c0-1.648 4.457-1.279 9.166-1.279 4.709 0 8.953-.582 8.953 1.066Z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="a"
          x1={254.833}
          y1={168.735}
          x2={254.483}
          y2={181.145}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEF5FF" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="b"
          x1={142.71}
          y1={169.554}
          x2={142.5}
          y2={177.009}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEF5FF" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UnknownError;
