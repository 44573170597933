import React from 'react';
import IconButton from '@lobox/uikit/Button/IconButton';
import cnj from '../utils/cnj';
import Flex from '../Flex';
import Modal from '../Modal';
import classes from './Confirmation.module.scss';
import Typography from '../Typography';

interface ConfirmationProps {
  title: string | React.ReactNode;
  onBackDropClick: () => void;
  styles?: any;
  children?: React.ReactNode;
  hideCloseButton?: boolean;
}

const Confirmation: React.FC<ConfirmationProps> = ({
  children,
  styles,
  onBackDropClick,
  title,
  hideCloseButton,
}) => {
  return (
    <Modal
      onBackDropClick={onBackDropClick}
      styles={{
        backdrop: classes.backdrop,
        wrapper: classes.wrapper,
        content: cnj(classes.container, styles?.content),
      }}
    >
      <Flex className={classes.snap} />

      <Flex as="header" className={classes.header}>
        <Typography size={20} height={32} font="bold" color="thirdText">
          {title}
        </Typography>

        {!hideCloseButton && (
          <IconButton
            colorSchema="modalHeaderIcon"
            onClick={onBackDropClick}
            name="times"
            type="far"
            className={classes.headerCloseButton}
          />
        )}
      </Flex>

      <Flex className={classes.content}>{children}</Flex>
    </Modal>
  );
};

export default Confirmation;
