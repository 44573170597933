import React from 'react';
import { ConfirmContext } from './confirm.provider';
import type { UseConfirmProps } from './useConfirm';

type ModalsType = {
  openConfirmDialog: (args: UseConfirmProps) => void;
  closeConfirm: () => void;
};

const useOpenConfirm = (): ModalsType =>
  React.useContext(ConfirmContext) as any;

export default useOpenConfirm;
