import classes from './index.module.scss';

import React from 'react';
import ListItem from '../ListItem';
import cnj from '../utils/cnj';

interface PopperItemProps {
  iconName?: string;
  label: string;
  onClick?: any;
  link?: string;
  iconType?: 'far' | 'fas' | 'fal';
  isSelected?: boolean;
  secondaryLabel?: string;
  labelClassName?: string;
  className?: string;
  labelTruncated?: boolean;
  secondLabelTruncated?: boolean;
}

const PopperItem = ({
  iconName,
  label,
  onClick,
  link,
  iconType = 'far',
  isSelected,
  secondaryLabel,
  labelClassName,
  className,
  labelTruncated,
  secondLabelTruncated,
}: PopperItemProps): JSX.Element => {
  return (
    <ListItem
      popperItem
      leftIcon={iconName}
      leftType={iconType}
      leftSize={14}
      leftColor={isSelected ? 'white' : 'thirdText'}
      label={label}
      labelColor={isSelected ? 'white' : 'thirdText'}
      labelSize={15}
      hover
      onClick={onClick}
      link={link}
      className={cnj(
        classes.popperItemRoot,
        className,
        isSelected && classes.active
      )}
      hoverColor={isSelected ? 'white' : 'thirdText'}
      hoverBgColor={isSelected ? 'trench' : 'hoverPrimary'}
      secondaryLabel={secondaryLabel}
      labelClassName={cnj(
        classes.labelClassName,
        iconName && classes.labelClassName,
        !!iconName && classes.labelClassNameHasIcon,
        labelClassName
      )}
      labelTruncated={labelTruncated}
      secondaryLabelTruncated={secondLabelTruncated}
    />
  );
};

export default PopperItem;
