import React from 'react';
import cnj from '../utils/cnj';
import Flex from '../Flex';
import classes from './ErrorBoundary.log.module.scss';

export interface ErrorBoundaryLogProps {
  error?: Error;
}

const ErrorBoundaryLog = ({ error }: ErrorBoundaryLogProps): JSX.Element => {
  return (
    <Flex
      as="a"
      href="#"
      rel="nofollow"
      className={cnj(classes.errorBoundaryLogRoot)}
    >
      {error?.toString()}
    </Flex>
  );
};

export default ErrorBoundaryLog;
