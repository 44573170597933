// @ts-nocheck

import React, { Fragment } from 'react';
// import * as Sentry from '@sentry/react';
import ErrorBoundaryFallback from './ErrorBoundary.fallback';

export interface ErrorBoundaryProps {
  Wrapper?: React.FC | typeof React.Component;
  wrapperProps?: any;
  className?: string;
  onError?: (error: Error) => void;
  renderFallback?: (args: any) => React.ReactElement;
  children?: ReactNode;
}

class App extends React.Component {
  render() {
    const { Wrapper, wrapperProps, className, renderFallback, children } =
      this.props;
    const renderFallbackHandler = (args) => {
      return (
        <ErrorBoundaryFallback
          {...args}
          wrapperProps={wrapperProps}
          Wrapper={Wrapper}
          className={className}
        />
      );
    };

    return children;
    // return (
    //   <Fragment
    //     fallback={renderFallback || renderFallbackHandler}
    //     showDialog={false}
    //   >
    //     {children}
    //   </Fragment>
    // );
  }
}

export default App;
