import React, { Fragment } from 'react';
import { useTranslation } from '@lobox/utils';
import UnknownError from '../shared/svg/UnknownError';
import cnj from '../utils/cnj';
import Box from '../Layout/Box';
import Button from '../Button/Button.component';
import ErrorBoundaryLog from './ErrorBoundary.log';
import useTheme from '../utils/useTheme';
import Typography from '../Typography';
import Divider from '../Divider';
import classes from './ErrorBoundary.fallback.module.scss';

export interface ErrorBoundaryFallbackProps {
  Wrapper?: React.FC | typeof React.Component;
  wrapperProps?: any;
  className?: string;
  error: Error;
  reset: () => void;
}

const ErrorBoundaryFallback = ({
  Wrapper = Fragment,
  wrapperProps,
  error,
  reset,
  className,
}: ErrorBoundaryFallbackProps): JSX.Element => {
  const { t } = useTranslation();
  const { isDark } = useTheme();

  return (
    <Wrapper {...wrapperProps}>
      <ErrorBoundaryLog error={error} />
      <Box
        className={cnj(classes.errorBoundaryFallbackRoot, className)}
        padding={20}
      >
        <UnknownError className={classes.unknownErrorSvg} />
        <Divider />
        <Typography
          size={20}
          height={24}
          font="700"
          color="thirdText"
          mt={80}
          mb={16}
        >
          {t('something_went_wrong')}
        </Typography>
        <Typography size={15} height={21} font="400" color="thirdText" mb={16}>
          {t('went_wrong_msg')}
        </Typography>
        <Button
          onClick={reset}
          label={t('reload_page')}
          schema={isDark ? 'secondary-dark' : 'semi-transparent'}
          className={classes.reloadButton}
        />
      </Box>
    </Wrapper>
  );
};

export default ErrorBoundaryFallback;
