// @ts-nocheck
import React from 'react';
import { useTranslation } from '@lobox/utils';
import isFunction from 'lodash/isFunction';
// import * as Sentry from '@sentry/react';
import useToast from '../Toast/useToast';
import ErrorBoundaryLog from './ErrorBoundary.log';
import ErrorBoundary from './ErrorBoundary';

export interface ErrorBoundaryToastProps {
  onError?: any;
  icon?: string;
  message?: string;
  children?: ReactNode;
}

const ErrorBoundaryToast: React.FC<ErrorBoundaryToastProps> = ({
  children,
  onError,
  icon,
  message,
}) => {
  const toast = useToast();
  const { t } = useTranslation();

  const handleError: any = (...args) => {
    if (isFunction(onError)) {
      onError(...args);
    } else {
      // Sentry.captureException(...args);
      toast({
        icon: icon ?? 'exclamation-circle',
        message: message ?? t('un_predictable_error'),
      });
    }
  };

  return (
    <ErrorBoundary
      renderFallback={({ error }) => <ErrorBoundaryLog error={error} />}
      onError={handleError}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryToast;
