import classes from './index.module.scss';

import React, { useRef } from 'react';
import cnj from '../utils/cnj';
import Typography from '../Typography';
import Icon from '../Icon';
import BaseButton from '../Button/BaseButton';
import Flex from '../Flex';
import type { IconFontType } from '../Icon';
import type { colorsKeys } from '../helpers/theme';
import type { TypographyProps } from '../Typography';
import OverflowTip from '../Typography/OverflowTip';

export interface ListItemProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => any;
  link?: string;
  className?: string;
  popperItem?: boolean;
  narrow?: boolean;
  autoWide?: boolean;
  leftSvg?: React.ReactNode;
  leftIcon?: string;
  leftType?: IconFontType;
  leftSize?: number;
  leftColor?: colorsKeys;
  rightSvg?: React.ReactNode;
  rightIcon?: string;
  rightType?: IconFontType;
  rightSize?: number;
  rightColor?: colorsKeys;
  renderContent?: React.ReactNode;
  action?: any;
  label?: string;
  secondaryLabel?: string;
  labelSize?: number;
  secondaryLabelSize?: number;
  secondaryLabelClassName?: string;
  labelColor?: colorsKeys;
  labelFont?: any;
  secondaryLabelColor?: colorsKeys;
  labelClassName?: string;
  labelsContainerClassName?: string;
  labelTruncated?: boolean;
  secondaryLabelTruncated?: boolean;
  hover?: boolean;
  hoverBgColor?: colorsKeys;
  hoverColor?: colorsKeys;
  labelProps?: Omit<TypographyProps, 'children'>;
  secondaryLabelProps?: Omit<TypographyProps, 'children'>;
  hasNarrowLabel?: boolean;
}

const ListItem = ({
  onClick,
  link,
  className,
  popperItem,
  narrow,
  autoWide,
  leftSvg,
  leftIcon,
  leftType,
  leftSize,
  leftColor,
  rightSvg,
  rightIcon,
  rightType,
  rightSize,
  rightColor,
  renderContent,
  action,
  label,
  secondaryLabel,
  labelSize = 16,
  labelFont,
  secondaryLabelSize = 16,
  labelColor,
  secondaryLabelColor,
  secondaryLabelClassName,
  labelClassName,
  labelsContainerClassName,
  labelTruncated,
  secondaryLabelTruncated,
  hover,
  hoverBgColor,
  hoverColor,
  labelProps: lP,
  secondaryLabelProps: sLP,
  hasNarrowLabel,
}: ListItemProps): JSX.Element => {
  const ref = useRef<any>(null);
  const { className: lClass, ...labelProps } = lP || {};
  const { className: slClass, ...secondaryLabelProps } = sLP || {};

  return (
    <BaseButton
      to={link}
      onClick={onClick}
      className={cnj(
        classes.listItemRoot,
        popperItem && classes.listItemRootPopper,
        hover && classes.hover,
        classes[`hoverBg-${hoverBgColor}`],
        classes[`hoverColor-${hoverColor}`],
        className
      )}
    >
      {leftIcon && (
        <Flex className={classes.leftIconContainer}>
          <Icon
            className={classes.leftIcon}
            type={leftType}
            name={leftIcon}
            color={leftColor}
            size={leftSize}
          />
        </Flex>
      )}
      {leftSvg}
      {hasNarrowLabel && (
        <Typography
          ref={ref}
          className={cnj(
            classes.label,
            labelClassName,
            lClass,
            'listItemLabel'
          )}
          size={labelSize}
          color={labelColor}
          font={labelFont}
          isTruncated={labelTruncated}
          {...labelProps}
        >
          {label}
        </Typography>
      )}
      {!narrow && (
        <>
          <Flex className={cnj(classes.container, labelsContainerClassName)}>
            {renderContent || (
              <>
                <OverflowTip
                  className={cnj(
                    classes.narrowSituation,
                    autoWide && classes.narrowSituationWide,
                    classes.label,
                    labelClassName,
                    lClass,
                    'listItemLabel'
                  )}
                  size={labelSize}
                  color={labelColor}
                  font={labelFont}
                  {...labelProps}
                >
                  {label}
                </OverflowTip>

                {secondaryLabel && (
                  <OverflowTip
                    className={cnj(
                      classes.narrowSituation,
                      autoWide && classes.narrowSituationWide,
                      classes.secondaryLabel,
                      labelClassName,
                      secondaryLabelClassName,
                      slClass
                    )}
                    size={secondaryLabelSize}
                    color={secondaryLabelColor}
                    isTruncated={secondaryLabelTruncated}
                    {...secondaryLabelProps}
                  >
                    {secondaryLabel}
                  </OverflowTip>
                )}
              </>
            )}
          </Flex>
          {rightIcon && (
            <Icon
              type={rightType}
              name={rightIcon}
              className={cnj(
                classes.narrowSituation,
                autoWide && classes.narrowSituationWide,
                classes.rightIcon,
                'rightIcon'
              )}
              color={rightColor}
              size={rightSize}
            />
          )}
          {action?.() || rightSvg}
        </>
      )}
    </BaseButton>
  );
};

export default ListItem;
