import classes from './index.module.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { useModalCount } from '@lobox/utils';
import cnj from '../utils/cnj';
import { Content, Backdrop } from './Modal.elements';
import ScrollView from '../ScrollView';
import type { ErrorBoundaryProps } from '../ErrorBoundary';
import ErrorBoundaryToast from '../ErrorBoundary/ErrorBoundaryToast';

interface StyleProps {
  backdrop?: string;
  wrapper?: string;
  header?: string;
  headerText?: string;
  content?: string;
}

interface ModalProps {
  children: React.ReactNode;
  styles?: Partial<StyleProps>;
  onBackDropClick?: Function;
  onError?: ErrorBoundaryProps['onError'];
}

const Modal: React.FC<ModalProps> = ({
  children,
  styles,
  onBackDropClick,
  onError,
}) => {
  useModalCount();

  const modal = (
    <ErrorBoundaryToast onError={onError}>
      <Backdrop
        onClick={onBackDropClick}
        className={cnj(classes.backdrop, styles?.backdrop)}
      />
      <ScrollView className={cnj(classes.wrapper, styles?.wrapper)}>
        <Content className={cnj(classes.content, styles?.content)}>
          {children}
        </Content>
      </ScrollView>
    </ErrorBoundaryToast>
  );
  return ReactDOM.createPortal(modal, document.body);
};

export default Modal;
