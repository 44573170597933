import React, { useState } from 'react';
import { useStateCallback } from '@lobox/utils';
import { createPortal } from 'react-dom';
import Button from '../Button';
import Flex from '../Flex';
import Confirmation from './Confirmation';
import Typography from '../Typography';
import classes from './useConfirm.module.scss';
import cnj from '../utils/cnj';

interface IConfirmProps {
  children?: JSX.Element;
}

export type UseConfirmProps = {
  title: string | React.ReactNode;
  message: string | React.ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  reverseButtons?: boolean;
  confirmCallback?: (args?: any) => void;
  cancelCallback?: (args?: any) => void;
  isAjaxCall?: boolean;
  isReverse?: boolean;
  apiProps?: {
    func?: Function;
    variables?: any;
    onSuccess?: Function;
    onError?: Function;
  };
};

export type UseConfirmReturnType = {
  openConfirm: Function;
  closeConfirm: Function;
  Confirm: React.FC<IConfirmProps>;
};

const useConfirm = ({
  confirmCallback,
  cancelCallback,
  title,
  message,
  confirmButtonText,
  cancelButtonText,
  reverseButtons,
  isAjaxCall,
  apiProps,
  isReverse,
}: UseConfirmProps): UseConfirmReturnType => {
  const [isOpen, setIsOpen] = useStateCallback(false);
  const [params, setParams] = useState(undefined);
  const [loading, toggleLoading] = useState(false);

  const setIsOpenFalse = () => {
    setIsOpen(false);
  };

  const closeHandler = () => {
    setIsOpenFalse();
    cancelCallback?.(params);
  };

  const openConfirm = (args?: any) => {
    setParams(args);
    setIsOpen(true);
  };

  const confirmHandler = () => {
    if (!isAjaxCall) {
      setIsOpenFalse();
      confirmCallback?.(params);
    } else if (!apiProps) {
      setIsOpenFalse();
    } else {
      toggleLoading(true);
      const { func, variables, onError, onSuccess } = apiProps;
      if (func) {
        func(variables, {
          onSuccess: (result: any) => {
            toggleLoading(false);
            setIsOpenFalse();
            onSuccess?.(result);
          },
          onError: (error: any) => {
            toggleLoading(false);
            setIsOpenFalse();
            onError?.(error);
          },
        });
      }
    }
  };

  const ConfirmDialog = () =>
    createPortal(
      <Confirmation
        onBackDropClick={isReverse ? confirmHandler : closeHandler}
        title={title}
      >
        <Typography height={21} mt={20}>
          {message}
        </Typography>

        <Flex
          className={cnj(classes.btnWrap, reverseButtons && classes.reverse)}
        >
          {!!cancelButtonText && (
            <Button
              className={classes.cancelBtn}
              onClick={closeHandler}
              label={cancelButtonText}
              schema="ghost"
              labelFont="bold"
              labelColor="primaryText"
              disabled={loading}
            />
          )}
          <Button
            className={classes.btn}
            onClick={confirmHandler}
            label={confirmButtonText}
            disabled={loading}
          />
        </Flex>
      </Confirmation>,
      document.body
    );

  const Confirm = ({ children }: IConfirmProps) => (
    <>
      {children}
      {isOpen && <ConfirmDialog />}
    </>
  );

  return {
    closeConfirm: closeHandler,
    openConfirm,
    Confirm,
  };
};

export default useConfirm;
