import React from 'react';
import Flex from '../Flex';

type StyleProps = {
  className?: string;
  onClick?: Function;
};

type ChildrenStyleProps = {
  className?: string;
  children?: React.ReactNode;
};

const Backdrop: React.FC<StyleProps> = ({ className, onClick }) => (
  <Flex onClick={onClick} className={className} />
);

const Wrapper: React.FC<ChildrenStyleProps> = ({ className, children }) => (
  <Flex className={className}> {children}</Flex>
);

const Container: React.FC<ChildrenStyleProps> = ({ className, children }) => (
  <Flex className={className}>{children}</Flex>
);

const Content: React.FC<ChildrenStyleProps> = ({ className, children }) => (
  <Flex className={className}>{children}</Flex>
);

export { Backdrop, Wrapper, Container, Content };
