'use client';
import React, { createContext } from 'react';
import { useStateCallback } from '@lobox/utils';
import useConfirm from './useConfirm';
import type { UseConfirmProps } from './useConfirm';

export const ConfirmContext = createContext<any>(undefined);

const ConfirmProvider: React.FC = ({ children }: any) => {
  const [dialogProps, setDialogProps] = useStateCallback<UseConfirmProps>({
    title: '',
    message: '',
  });

  const { openConfirm, closeConfirm, Confirm } = useConfirm({
    title: dialogProps?.title,
    message: dialogProps?.message,
    confirmButtonText: dialogProps?.confirmButtonText,
    cancelButtonText: dialogProps?.cancelButtonText,
    reverseButtons: dialogProps?.reverseButtons,
    confirmCallback: dialogProps?.confirmCallback,
    cancelCallback: dialogProps?.cancelCallback,
    apiProps: dialogProps?.apiProps,
    isAjaxCall: dialogProps?.isAjaxCall,
    isReverse: dialogProps?.isReverse,
  });

  const openConfirmDialog = (props: UseConfirmProps) => {
    setDialogProps(props, openConfirm);
  };

  return (
    <ConfirmContext.Provider
      value={{
        openConfirmDialog,
        closeConfirm,
      }}
    >
      {children}
      <Confirm />
    </ConfirmContext.Provider>
  );
};

export default ConfirmProvider;
